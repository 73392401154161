import FonctionTransformer from './FonctionTransformer'
import TiersContactTransformer from './TiersContactTransformer'
import TiersHorseTransformer from './TiersHorseTransformer'
import TierTransformer from './TierTransformer'
import Transformer from './Transformer'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} tiers_relations
 */
export class TiersRelationsTransformer extends Transformer {

	table = 'tiers_relations'

	tiers_from_withphone(item) {
		return this.fetchRelationship(item, 'tiersrelations_from')
		.then(tiers => {
			return TierTransformer.process(tiers, 'withPhone')
		})
	}

	tiers_to_withphone(item) {
		return this.fetchRelationship(item, 'tiersrelations_to')
		.then(tiers => {
			return TierTransformer.process(tiers, 'withPhone')
		})
	}

	fonction(item) {
		return this.fetchRelationship(item, 'tiersrelations_fonction')
		.then(fonction => {
			return FonctionTransformer.process(fonction)
		})
	}

	contact(item) {
		return this.fetchRelationship(item, 'tiersrelations_contact')
		.then(tierscontact => {
			return TiersContactTransformer.process(tierscontact, 'withContactAndFonction')
		})
	}

	fetch(col) {
		this.additionalColumns({
			'tiers_from_withphone': this.tiers_from_withphone,
			'tiers_to_withphone': this.tiers_to_withphone,
			'fonction': this.fonction,
			'contact': this.contact
		})

		return col
	}

	async transform(tiers_relations) {
		return {
			'tiersrelations_id': tiers_relations.tiersrelations_id,
			'tiersrelations_from': tiers_relations.tiers_from_withphone,
			'tiersrelations_to': tiers_relations.tiers_to_withphone,
			'fonction': tiers_relations.fonction,
			'contact': tiers_relations.contact ? tiers_relations.contact : null
		}
	}
}

export default TiersRelationsTransformer
