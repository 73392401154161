import CacheCleaner from "./CacheCleaner"
import CacheManager from "../CacheManager"
import ContractCleaner from "./ContractCleaner"
import Navigation from "@/mixins/Navigation.js";
import store from '@/store/index.js';


export default class TierCleaner extends CacheCleaner {

	async onMutation(tiers_ids) {
		await this.init()

		await CacheManager.inst().onObjectsMutation('tiers', tiers_ids, ['phone_combine'])

		if(store.state.userAccess.hasSaillieAccess) {
			// Vider le cache de tous les avenants concernés par ce tiers en tant que CMEP
			return this.db().t('contract_avenant')
			.then(table => {
				return table.where('avenant_cmep').anyOf(tiers_ids).toArray()
			})
			.then(avenants =>{
				const contracts_id = avenants.map(avenant => avenant.avenant_contract);
				return this.onMutationContract(contracts_id);
			})
		}
	}

	// Mise à jour du caches sur les contracts en lien en tant que CMEP
	async onMutationContract(contracts_id) {
		await ContractCleaner.inst().onMutation(contracts_id, ['avenant'])
	}
}
