import CacheCleaner from "./CacheCleaner"
import ContractCleaner from "./ContractCleaner"

export default class ContractAvenantCleaner extends CacheCleaner {

	async onMutation(avenant_ids, columns) {
		await this.init()

		return this.db().t('contract_avenant')
		.then(table => {
			return table.where('avenant_id').anyOf(avenant_ids).toArray()
		})
		.then(avenants =>{
			const contracts_id = avenants.map(avenant => avenant.avenant_contract);
			return this.onMutationContract(contracts_id, columns);
		})
	}

	// Mise à jour du caches sur les contracts en lien des avenants
	async onMutationContract(contracts_id, columns) {
		await ContractCleaner.inst().onMutation(contracts_id, columns)
	}
}
