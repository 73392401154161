import Transformer from './Transformer'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} season
 */
export default class NumberTemplateTransformer extends Transformer {


    async transform (number_template) {
        return {
            'numbertemplate_id'			: number_template.numbertemplate_id,
            'numbertemplate_valide'     : number_template.numbertemplate_valide,
            'numbertemplate_prefix'     : number_template.numbertemplate_prefix,
            'numbertemplate_pattern'	: number_template.numbertemplate_pattern,
            'numberable_id'     		: number_template.numberable_id,
            'numberable_type'     		: number_template.numberable_type,
            'numberable_field'     		: number_template.numberable_field,
        }
    }
}
