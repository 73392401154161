import Transformer from './Transformer'

export default class TiersRibTransformer extends Transformer {

	table = 'tiers_rib'

    transform(tiers_rib) {
        return {
			'tiersrib_id': tiers_rib.tiersrib_id,
			'tiersrib_tiers': tiers_rib.tiersrib_tiers,
			'tiersrib_content': tiers_rib.tiersrib_content,
        }
	}
}