<template>
    <div>
        <h2>{{ $t("tiers.historique") }}</h2>

        <div class="box">
            <CustomTable
				ref="table"
                id_table="tiers_horse_historique_horse"
                :items="filtredHistorique"
                :busy.sync="table_busy"
                primaryKey="tiershorsepart_id"
                :hide_if_empty="true"
            />
        </div>
    </div>
</template>

<script type="text/javascript">
    import Vue from 'vue'
    import TableAction from "@/mixins/TableAction.js"
    import Navigation from "@/mixins/Navigation.js"
    import TiersMixin from "@/mixins/Tiers.js"
    import HorseMixin from "@/mixins/Horse.js"
    import CustomTable from "GroomyRoot/components/Table/CustomTable"
    import { EventBus } from 'EventBus'

    export default {
        name: "TiersHorseHistoriqueHorse",
        mixins: [TableAction, Navigation, TiersMixin, HorseMixin],
        props: ['tiers_id'],
        data () {
            return {
                tiers_horse: null,
                table_busy: true,
                events_tab: {
                    'TableAction::goToDeleteTiersHorseHistoriqueHorse': (params) => {
                        this.deleteTiersHorse(params.tiershorsepart_id)
                    }
                }
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                await this.loadHorseHistoriqueComponent()
            },
            async loadHorseHistoriqueComponent() {
				this.table_busy = true
				this.$refs.table.resetCachedItems()
				const tiers = await this.loadHorsePart(this.tiers_id)
                if(tiers) {
					let temp = []
					tiers.tiers_horse.forEach(tiers_horse => {
						tiers_horse.tiershorse_part.forEach((tiershorse_part) => {
                            // Si le cheval n'a pu être fetch, la relation tiershorse/horse est cassée
                            if (tiers_horse.horse) {
                                temp.push({
                                    horse_nom: tiers_horse.horse.horse_nom,
                                    tiershorsepart_id: tiershorse_part.tiershorsepart_id,
                                    tiershorsepart_propriete: tiershorse_part.tiershorsepart_propriete,
                                    tiershorsepart_pension: tiershorse_part.tiershorsepart_pension,
                                    tiershorsepart_frais: tiershorse_part.tiershorsepart_frais,
                                    tiershorsepart_contract: tiershorse_part.tiershorsepart_contract,
                                    tiershorsepart_date: tiershorse_part.tiershorsepart_date,
                                    tiershorsepart_entraineur: tiershorse_part.tiershorsepart_entraineur,
                                    tiershorsepart_jockey: tiershorse_part.tiershorsepart_jockey
                                })
                            }
						})
					})

                    this.tiers_horse = temp
                } else {
                    this.tiers_horse = null
                }

                this.table_busy = false
            },
            async deleteTiersHorse(tiershorsepart_id) {
                this.deleteHorseTiersPart(tiershorsepart_id)
                .then(result => {
                    this.loadHorseHistoriqueComponent()
                    EventBus.$emit('DeleteTiersHorseHistoriqueHorseSuccess')
                    this.successToast('toast.info_modif_succes')
                })
                .catch(e => {
                    if (typeof(e) === 'string') {
						this.form_message = e
						this.failureToast(e)
					}
					else {
						console.error(e)
					}
                })
            }
        },
        computed: {
            filtredHistorique: function() {
                return this.tiers_horse
            }
        },
        components: {
            CustomTable
        }
    }
</script>
