import RootOfflineTableMixin from 'GroomyRoot/mixins/OfflineTable.js'
import OfflineUtils from '@/assets/js/utils/offline'
import Queue from '@/assets/js/utils/queue'
import Common from '@/assets/js/common'

const STATES = {
	CREATED: 0,
	INITIALIZING: 1,
	READY: 2
}

export default {
	mixins: [RootOfflineTableMixin],
    props: {
        transformer: { type: Array },
    },

    data() {
		return {
			pageItems: null,
			oldPageIds: null,
			totalRows: -1,
			isFetching: false,
			internalTransformer: null,
			transformerState: STATES.CREATED,
			queue: new Queue(),
			fetchQueue: new Queue(),
			oldFilter: null,
			oldSort: null,
			oldColumns: null,
			onFirstSync: false,
			isIndexing: false,
			tableName: null
		}
	},

	computed: {
		requestId() {
			return `table-${this._uid}`
		},
		fetchRequestId() {
			return `fetch-table-${this._uid}`
		},
		hasOnlinegroupbyPagination() {
			return !this.internalTransformer && this.hasPaginationSelect && !!this.group_by
		},
		totalRowsTrad() {
			// Pour ne pas afficher un nombre de résultats faux pendant le chargement
			if(this.localBusy) {
				return ''
			}

			// En online, si on a un group by non paginé, ou un affichage classique
			if(!this.internalTransformer && !this.hasOnlinegroupbyPagination) {
				if(this.computed_items.length === 0) return ''
				else if(this.computed_items.length === 1) return `1 ${this.getTrad('table.general.resultat')}`
				return `${this.computed_items.length} ${this.getTrad('table.general.resultats')}`
			}
			else if(this.totalRows < 1) return ''
			else if(this.totalRows === 1) return `1 ${this.getTrad('table.general.resultat')}`

			return `${this.totalRows} ${this.getTrad('table.general.resultats')}`
		}
	},

	created() {
		this.tableRenderedDuringFirstSync()
		this.checkTableIndexation()
	},

    methods: {
		async itemsProvider(ctx) {
			// Si le provider est appelé plusieurs fois alors que le transformer n'est pas initialisé (ex: Carnet d'adresses)
			const internalTransformer = await this.getInternalTransformer()

			const filter = JSON.stringify(ctx.filter)
			// Le pickItemsIds va récupérer la bonne page
			if (filter === this.oldFilter) {
				await this.onItemsFetched(false)
				return
			}

			// On se souvient du filtre pour vérifier si le filtre a changé au prochain appel
			this.oldFilter = filter

			internalTransformer.cancel(this.requestId)
			internalTransformer.cancel(this.fetchRequestId)

			const flushQueue = this.queue.flush()
			const flushFetchQueue = this.fetchQueue.flush()
			await Promise.all([flushQueue, flushFetchQueue])

			await this.queue.enqueue(
				async () => {
					this.localBusy = true
					this.isCallbackLocked = false

					try {
						await internalTransformer.filter(ctx, this.requestId)
						await this.onItemsFetched(true)
					}
					catch(err) {
						if(err.name === 'CancelError') {
							// En cas d'annulation de la requete il faut considérer qu'on a pas d'items
							this.oldFilter = null
						}
						else {
							console.error(err)
						}
					}

					this.localBusy = false
				},
				this
			)
		},

		async onItemsFetched(forceSort=true) {
			const internalTransformer = await this.getInternalTransformer()

			internalTransformer.cancel(this.fetchRequestId)
			await this.fetchQueue.flush()

			await this.fetchQueue.enqueue(async () => {
				this.localBusy = false
				this.isFetching = true

				this.totalRows = await internalTransformer.getTotalRows(this.requestId)

				const sortOrder = `${this.ctx.sortBy}-${this.ctx.sortDesc}`
				if(forceSort || this.oldSort !== sortOrder) {
					await internalTransformer.sortRequestItems(this.requestId, this.ctx)
					this.oldSort = sortOrder
				}

				let pageIds = await internalTransformer.pickItemsIds(this.requestId, this.ctx)
				const pageIdsStr = JSON.stringify(pageIds)

				const oldColumnsStr = JSON.stringify(this.ctx.columns)

				if (pageIdsStr !== this.oldPageIds || this.oldColumns !== oldColumnsStr) {
					// Pour ne pas afficher une page fausse pendant le isFetching
					this.pageItems = []

					pageIds = this.$sync.replaceWithReplicated(this.tableName, pageIds)
					this.pageItems = await internalTransformer.retrievePage(pageIds, this.ctx, this.fetchRequestId)
					// Lors de l'annulation des requetes on renvoie un array vide donc il ne faut pase
					// se souvenir d'IDs qui ne sont pas dans this.pageItems
					this.oldPageIds = this.pageItems.length === pageIds.length ? pageIdsStr : '[]'
					this.oldColumns = oldColumnsStr
				}

				this.isFetching = false
			})
		},

		async resetCachedItems() {
			this.selected = []
			if (this.transformer) {
				const internalTransformer = await this.getInternalTransformer()
				if (internalTransformer) {
					this.oldFilter = null
					this.oldSort = null
					this.oldPageIds = null
					await internalTransformer.emptyCache()
				}
			}
		},

		async getInternalTransformer() {
			if (this.transformerState !== STATES.READY) {
				if (this.transformerState === STATES.CREATED) {
					this.transformerState = STATES.INITIALIZING

					this.internalTransformer = await OfflineUtils.getTransformerInstance(this.transformer[0], this.transformer[1])
					await this.internalTransformer.init()

					this.tableName = await this.internalTransformer.table

					this.transformerState = STATES.READY
				}
				else {
					await Common.waitUntil(() => (this.transformerState === STATES.READY))
				}
			}

			return this.internalTransformer
		},

		tableRenderedDuringFirstSync() {
			this.onFirstSync = this.$sync.getLastSync('optionnal') === 0
		},

		async indexTable() {
			this.$sync.indexTable(this.tableName)
			this.successToast("storage.indexation_started")
		},

		async checkTableIndexation() {
			if(this.transformer) {
				const internalTransformer = await this.getInternalTransformer()
				this.isIndexing = await internalTransformer.isIndexingTable()
			}
		},

		async getTransformerItemsBetween(lastPageId, ctx, a, b) {
			const internalTransformer = await this.getInternalTransformer()
			return internalTransformer.pickItemsBetweenIds(lastPageId, this.requestId, ctx, a, b)
		}
	},

	beforeDestroy() {
		if (this.internalTransformer) {
			this.internalTransformer.cancelAll()
			this.internalTransformer.emptyCache()
		}
	}
}
