import Tools from '@/mixins/Tools.js'

export default {
    mixins: [Tools],
    data () {
      return {
        fields_excluded: ['notes.type_fonts', 'formatted_invoices', 'contracts', 'mare.horse.horse_tiers', 'paillettes_date']
      }
    },
    methods: {
        /**
         * Télécharges des données au format CSV
         * @param {Array} ls liste d'objets JS
         * @param {Object} headers objet ayant le label pour clé et la clé à récupérer en valeur. Ex: { "Mon header de colonne" : ma_cle }
         * @param {String} [filename=export.csv] nom du fichier à télécharger
         * @param {String} [delimiter=;] caractère qui délimite les colonnes
         */
        downloadJsonToCsv(ls, headers, filename, delimiter, float_fields, id_table) {
            delimiter = delimiter || ';'
            const separateur_decimal = this.getConfig("formatExport", ",")
            let rows = [
                Object.values(headers)
            ]

            ls.forEach(item => {
                let row = []
                Object.keys(headers).forEach(key => {
                  let value = this.getNestedObjectString(item, key)
                  if(Array.isArray(value) && key.includes(['formatted_invoices', 'contracts', 'mare.horse.horse_tiers'])) {
                    value = value[0]
                  }
                  if(value && !this.fields_excluded.includes(key) && (key.includes('_date') || (typeof value === 'object' && !Array.isArray(value) && !isNaN(Date.parseTz(value))))) {
                    try {
                      let date = Date.parseTz(value).toLocaleDateString()
                      value = (date == 'Invalid Date') ? value : date
                    }
                    catch (err) {
                      console.error(err);
                    }
                  }
                  else if(float_fields.includes(key)) {
                    if (typeof value === 'string' || value instanceof String) {
                      value = value.replace(' ', '')
                    }
                    else {
                      if(value == undefined) {
                        console.warn(item, key)
                      }
                      else {
                        value = value.toFixed(2).toString()
                      } 
                    }
                    if(separateur_decimal == "."){
                      value = value.replace(',', '.')
                    } else {
                      value = value.replace('.', ',')
                    }
                  }
                  else if(value && Object.entries(value).length > 0 && key == 'formatted_invoices') {
                    if(Array.isArray(value)) {
                      value = value.map(val => {
                        return val.invoice_num + " (" + this.getTrad('invoice.invoice_balance') + ":" + val.invoice_balance + ")"
                      }).join(' ')
                    }
                    else {
                      value = value.invoice_num + " (" + this.getTrad('invoice.invoice_balance') + ":" + value.invoice_balance + ")"
                    }
                  }
                  else if(value && Object.entries(value).length > 0 && key == 'contracts') {
                    if(Array.isArray(value)) {
                      value = value.map(val => {
                        return val.contract_num
                      }).join(' ')
                    }
                    else {
                      value = value.contract_num
                    }
                  }
                  else if(value && Object.entries(value).length > 0 && key == 'horses') {
                    if(Array.isArray(value)) {
                      value = value.map(val => {
                        return val.horse_name || val.horse_nom
                      }).join(', ')
                    }
                    else {
                      value = value.horse_name || value.horse_nom
                    }
                  }
                  else if(value && Object.entries(value).length > 0 && key == 'mare.horse.horse_tiers') {
                    if(Array.isArray(value)) {
                      value = value.map(val => {
                        return val.tiers_rs
                      }).join(' ')
                    }
                    else {
                      value = value.tiers_rs
                    }
                  }
                  else if(value && key == 'avenant.type_monte.contract_type_monte.contracttypemonte_label') {
                    value = this.getTrad(value)
                  }
                  else if(value && key == 'stallion' && id_table == 'plan_cuve') {
                    value = value.map(val => val.label).join(',')
                  }
                  else if (id_table === "horse" && key === "sexe_label"){
                    value = this.$t('horse.sex.' + value)
                  }
                  else if(value && Object.entries(value).length > 0 && key == 'all_client_account') {
                    if(Array.isArray(value)) {
                      value = value.map(val => {
                        return val.tiersclientaccount_accountingplan.tiers.tiers_rs + " : " + val.tiersclientaccount_number
                      }).join(', ')
                    }
                  }
                  value = (value === undefined || value === null || (Object.entries(value).length == 0 && !Number.isInteger(value))) ? '' : value
                  if(typeof value === 'string') {
                    value = value.replace(/[\r\n]/gm, ' ') // j'enlève les retours à la ligne
                    value = value.replace(/(<([^>]+)>)/gi, ' ') // j'enlève les balises HTML
                  }

                  // si valeur de type nombre ou colonne float et que le delimiteur est un ; => sans quote
                  if((typeof value == 'number' || float_fields.includes(key)) && delimiter == ';') {
                    row.push(value)
                  } else {
                    row.push(`"${value}"`)
                  }
                })
                rows.push(row)
            })

            this.downloadCsv(rows, filename, delimiter)
        },

        /**
         * Télécharges des données au format CSV
         * @param {Array} rows liste de lignes à télécharger en CSV
         * @param {String} [filename=export.csv] nom du fichier à télécharger
         * @param {String} [delimiter=;] caractère qui délimite les colonnes
         */
        downloadCsv (rows, filename, delimiter) {
          filename = filename || 'export.csv'
          delimiter = delimiter || ','
          const csvRows = []
          const regex = /<br\s*[/]?>/gi;
          rows.forEach(row_array => {
            csvRows.push(row_array.join(delimiter).replace(regex, ""))
          })

          const csvContent = csvRows.join('\r\n')
          this.downloadData(csvContent, filename)
        },
  
        /**
         * Télécharge des données sous forme de chaîne de caractères
         * @param {String} data dataURI à télécharger
         * @param {String} filename nom du fichier à télécharger
         */
        downloadData (fileContent, fileName, type="application/vnd.ms-excel", charset="UTF-16LE") {
          if (window.navigator.msSaveBlob) { // IE
            const blob = new Blob([fileContent], {type:  `${type};charset=${charset};`});
            window.navigator.msSaveOrOpenBlob(blob, fileName)
          } else {
            const encodedUri = `data:${type}; charset=${charset},${encodeURIComponent('\uFEFF' + fileContent)}`

            const link = document.createElement('a')
            link.setAttribute('href', encodedUri)
            link.setAttribute('target', '_blank');
            link.setAttribute('download', fileName);

            link.click() // This will download the data file named "my_data.csv".
            link.remove()
          }
        },

        downloadJsonToExcel(ls, headers, filename, float_fields, id_table) {
          const separateur_decimal = this.getConfig("format_export", ",")
          let fields = Object.values(headers)
          let rows = []
          ls.forEach(item => {
              let row = []
              Object.keys(headers).forEach(key => {
                let value = this.getNestedObjectString(item, key)
                if(Array.isArray(value) && key.includes(['formatted_invoices', 'contracts', 'mare.horse.horse_tiers'])) {
                  value = value[0]
                }
                if(value && !this.fields_excluded.includes(key) && (key.includes('_date') || (typeof value === 'object' && !Array.isArray(value) && !isNaN(Date.parseTz(value))))) {
                 try {
                    let date = Date.parseTz(value).toLocaleDateString()
                    value = (date == 'Invalid Date') ? value : date
                  }
                  catch (err) {
                    console.error(err);
                  }
                }
                else if(float_fields.includes(key)) {
                  if (typeof value === 'string' || value instanceof String) {
                    value = value.replace(' ', '')
                  }
                  else {
                    if(value == undefined) {
                      console.warn(item, key)
                    }
                    else {
                      value = value.toFixed(2).toString()
                    } 
                  }
                  if(separateur_decimal == "."){
                    value = value.replace(',', '.')
                  } else {
                    value = value.replace('.', ',')
                  }
                }
                else if(value && Object.entries(value).length > 0 && key == 'formatted_invoices') {
                  if(Array.isArray(value)) {
                    value = value.map(val => {
                      return val.invoice_num + " (" + this.getTrad('invoice.invoice_balance') + ":" + val.invoice_balance + ")"
                    }).join(' ')
                  }
                  else {
                    value = value.invoice_num + " (" + this.getTrad('invoice.invoice_balance') + ":" + value.invoice_balance + ")"
                  }
                }
                else if(value && Object.entries(value).length > 0 && key == 'contracts') {
                  if(Array.isArray(value)) {
                    value = value.map(val => {
                      return val.contract_num
                    }).join(' ')
                  }
                  else {
                    value = value.contract_num
                  }
                }
                else if(value && Object.entries(value).length > 0 && key == 'horses') {
                  if(Array.isArray(value)) {
                    value = value.map(val => {
                      return val.horse_name || val.horse_nom
                    }).join(', ')
                  }
                  else {
                    value = value.horse_name || value.horse_nom
                  }
                }
                else if(value && Object.entries(value).length > 0 && key == 'mare.horse.horse_tiers') {
                  if(Array.isArray(value)) {
                    value = value.map(val => {
                      return val.tiers_rs
                    }).join(' ')
                  }
                  else {
                    value = value.tiers_rs
                  }
                }
                else if(value && key == 'avenant.type_monte.contract_type_monte.contracttypemonte_label') {
                  value = this.getTrad(value)
                }
                else if(value && key == 'stallion' && id_table == 'plan_cuve') {
                  value = value.map(val => val.label).join(',')
                }
                else if (id_table === "horse" && key === "sexe_label"){
                  value = this.$t('horse.sex.' + value)
                }
                else if(value && Object.entries(value).length > 0 && key == 'all_client_account') {
                  if(Array.isArray(value)) {
                    value = value.map(val => {
                      return val.tiersclientaccount_accountingplan.tiers.tiers_rs + " : " + val.tiersclientaccount_number
                    }).join(', ')
                  }
                }
                value = (value === undefined || value === null || (Object.entries(value).length == 0 && !Number.isInteger(value))) ? '' : value
                if(typeof value === 'string') {
                  value = value.replace(/[\r\n]/gm, ' ') // j'enlève les retours à la ligne
                  value = value.replace(/(<([^>]+)>)/gi, ' ') // j'enlève les balises HTML
                }
                row.push(`${value}`)
              })
              rows.push(row)
          })

          this.downloadExcel(fields, rows, filename)
        },

        downloadExcel (headers, rows, filename) {
          filename = filename || 'export.xls'

          let content = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">' +
          '<head><meta name=ProgId content=Excel.Sheet> <meta name=Generator content="Microsoft Excel 11"><meta http-equiv="Content-Type" content="text/html; charset=UTF-8">' +
          '<!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>Sheet1</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions>' +
          '</x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><style>br {mso-data-placement: same-cell;}</style></head><body><table><thead><tr><th>'

          content += headers.join('</th><th>')
          content += '</th></tr></thead><tbody>'

          rows.forEach(row => {
            content += "<tr>"
            row.forEach(r => {
              content += "<td>"+r+"</td>"
            })
            content += "</tr>"
          })

          content += '</tbody></table></body></html>'

          this.downloadData(content, filename)
        },
    }
}
