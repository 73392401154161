import { EventBus } from 'EventBus'

let TableActionMixin = {
    props: {
        uniqueEventId: { type: String, default: '' }
    },
	methods: {
		// ACTES
		goToAddHorse() {
			this.$router.push({ name: 'addHorse' })
		},
		goToAddActe(horses = []) {
			let params = { from: this.$route.name }
            params = this.constructHorseRouteParams(horses, params)
            
            if(this.button_action_event_only) {
                this.sendEvent('TableAction::acteAjout', params)
            }
            else {
                this.$router.push({ name: 'acteAjout', params: params })
            }
		},
		goToEditActe(actes) {
			const acte_ids = this.constructRouteParams(actes, 'actes_id')
            const params = { acte_id: acte_ids, from: this.$route.name, state: 'modification', horse_id: this.$route.params.horse_id, 'lot': false }
            
            if(this.button_action_event_only) {
                this.sendEvent('TableAction::acteAjout', params)
            }
            else {
                this.$router.push({ name: 'acteAjout', params: params })
            }
		},
		goToEditActeLot(actes) {
			const acte_ids = this.constructRouteParams(actes, 'actes_id')
            const params = { acte_id: acte_ids, from: this.$route.name, state: 'modification', horse_id: this.$route.params.horse_id, 'lot': true }
            
            if(this.button_action_event_only) {
                this.sendEvent('TableAction::acteAjout', params)
            }
            else {
                this.$router.push({ name: 'acteAjout', params: params })
            }
		},
		goToValidActe(actes) {
			const acte_ids = this.constructRouteParams(actes, 'actes_id')
            const params = { acte_id: acte_ids, from: this.$route.name, state: 'validation', horse_id: this.$route.params.horse_id }

            if(this.button_action_event_only) {
                this.sendEvent('TableAction::acteAjout', params)
            }
            else {
                this.$router.push({ name: 'acteAjout', params: params })
            }
		},
		goToValidActeFromHorse(actes) {
			const acte_ids = this.constructRouteParams(actes, 'actes_id')
            const params = { acte_id: acte_ids, from: this.$route.name, state: 'validation', horse_id: this.$route.params.horse_id }
            this.$router.push({ name: 'acteAjout', params: params })
            
		},
		goToDeleteActe(actes) {
			this.sendEvent('TableAction::DeleteActes', actes)
		},
		goToReplanActe(actes) {
			this.sendEvent('TableAction::ReplanActe', actes)
		},
		goToReplanActeFromHorse(actes) {
			EventBus.$emit('TableAction::ReplanActe', actes)
		},

		// ACTES TYPES
		goToAddActeType() {
			const params = { from: this.$route.name }
			this.$router.push({ name: 'acteTypeAjout', params: params })
		},
		goToEditActeType(actes_types) {
			EventBus.$emit('TableAction::EditActesTypes', actes_types[0])
		},
		goToEditActeTypeClientArea(actes_types) {
			EventBus.$emit("TableAction::goToEditActeTypeClientArea", actes_types)
		},
		goToDeleteActesTypes(actes_types) {
			EventBus.$emit('TableAction::DeleteActesTypes', actes_types)
		},

		// MOUVEMENTS
		goToAddMouvement(horses = []) {
			let params = { from: this.$route.name }
			params = this.constructHorseRouteParams(horses, params)
			this.$router.push({ name: 'mouvementAjout', params : params })
		},
		goToAddMouvementEntree(horses = []) {
			let params = { from: this.$route.name, type: 'entree' }
			params = this.constructHorseRouteParams(horses, params)
			this.$router.push({ name: 'mouvementAjout', params : params })
		},
		goToAddMouvementSortie(horses = []) {
			let params = { from: this.$route.name, type: 'sortie' }
			params = this.constructHorseRouteParams(horses, params)
			this.$router.push({ name: 'mouvementAjout', params : params })
		},
		goToAddMouvementInterne(horses = []) {
			let params = { from: this.$route.name, type: 'interne' }
			params = this.constructHorseRouteParams(horses, params)
			this.$router.push({ name: 'mouvementAjout', params : params })
		},
		goToEditMouvement(mouvements) {
			// Si le mouvement est une provenance, on n'autorise pas sa modification directe
			if(!mouvements[0].mouvement_date || mouvements[0].mouvement_date == '0000-00-00') {
				this.infoToast('toast.mouvement.info_modif_prov_impossible')
				return
			}

			const horse_id = this.$route.params.horse_id
			const mouvement_ids = this.constructRouteParams(mouvements, 'mouvement_id')
			const horse_ids     = this.constructRouteParams(mouvements, 'mouvement_horse')
			const params = { horse_id: horse_ids, mouvement_id: mouvement_ids, from: this.$route.name }

			this.$router.push({ name: 'WriteMouvement', params : params })
		},
		goToDeleteMouvement(mouvements) {
			EventBus.$emit('TableAction::DeleteMouvements', mouvements)
		},

		// ORDONNANCES
		goToAddOrdonnance(horses = []) {
			let params = { from: this.$route.name }
			params = this.constructHorseRouteParams(horses, params)
			this.$router.push({ name: 'ordonnanceAjout', params : params })
		},
		goToViewDocumentOrdonnance(documents) {
			EventBus.$emit("TableAction::DocumentReady", documents[0])
		},
		goToDeleteDocumentOrdonnance(documents) {
			EventBus.$emit("TableAction::DeleteDocumentOrdonnance", documents)
		},
		goToEditOrdonnance(documents) {
			let horse_id = documents[0].horse ? documents[0].horse.horse_id : null
			let params = { from: this.$route.name, document_id: documents[0].document_id, horse_id }
			this.$router.push({ name: 'ordonnanceEdit', params : params })
		},

		// REGISTRES ELEVAGE
		goToSeeRegistry(registres) {
			const registre_id = this.constructRouteParams(registres, 'exporthistory_id')
			EventBus.$emit("RegistreHistory::SeedDocument", registre_id)
		},

		goToDownloadRegistry(registres) {
			const registre_ids = this.constructRouteParams(registres, 'exporthistory_id')
			EventBus.$emit("RegistreHistory::DownloadDocuments", registre_ids)
		},

		// HORSES
		goToPrintInfosHorse(horses) {
			const horse_ids = this.constructRouteParams(horses, 'horse_id')
			let params = { horse_ids: horse_ids }
			EventBus.$emit('TableAction::goToPrintInfosHorse', params)
		},
		async goToDeleteHorses(horsesDelete) {
			EventBus.$emit('TableAction::DeleteHorses', horsesDelete)
		},

		// ARTICLES
		goToAddArticle() {
			EventBus.$emit("TableAction::goToAddArticle", {})
		},
		goToEditArticle(articles) {
			const articles_id = this.constructRouteParams(articles, 'articles_id')
			let params = { articles_id: articles_id }

			EventBus.$emit("TableAction::goToEditArticle", params)
		},
		goToDeleteArticle(articles) {
			const articles_ids = this.constructRouteParams(articles, 'articles_id')
			let params = { articles_ids : articles_ids }

			EventBus.$emit("TableAction::goToDeleteArticle", params)
		},

		// TIERS
		goToAddTiers() {
			EventBus.$emit("TableAction::goToAddTiers", {})
		},
		goToEditTiers(tiers) {
			const tiers_id = this.constructRouteParams(tiers, 'tiers_id')
			let params = { tiers_id: tiers_id }
			EventBus.$emit("TableAction::goToEditTiers", params)
		},
		goToAddContact() {
			const tiers_id = this.$route.params['tiers_id']
			const horse_id = this.$route.params['horse_id']
			let params = { tiers_id: tiers_id, horse_id: horse_id }
			EventBus.$emit("TableAction::goToAddContact", params)
		},
		goToImportTiers() {
			EventBus.$emit("TableAction::goToImportTiers", {})
		},
		goToEditContact(contact) {
			const contact_id = this.constructRouteParams(contact, 'contact_id')
			const tiers_id = this.$route.params['tiers_id']
			const horse_id = this.$route.params['horse_id']
			let params = { tiers_id: tiers_id, contact_id: contact_id, horse_id: horse_id }
			EventBus.$emit("TableAction::goToEditContact", params)
		},
		goToDeleteContact(contact) {
			const contact_id = this.constructRouteParams(contact, 'contact_id')
			const tiers_id = this.$route.params['tiers_id']
			const horse_id = this.$route.params['horse_id']
			let params = { tiers_id: tiers_id, contact_id: contact_id, horse_id: horse_id }
			EventBus.$emit("TableAction::goToDeleteContact", params)
		},
		goToAddTiersHorseHorse() {
			EventBus.$emit("TableAction::goToAddTiersHorseHorse", {})
		},
		goToEditTiersHorseHorse(tiers_horse) {
			const tiershorse_id = this.constructRouteParams(tiers_horse, 'tiershorse_id')
			let params = { tiershorse_id: tiershorse_id }
			EventBus.$emit("TableAction::goToEditTiersHorseHorse", params)
		},
		goToDeleteTiersHorseHorse(tiers_horse) {
			const tiershorse_id = this.constructRouteParams(tiers_horse, 'tiershorse_id')
			const horse_id = this.constructRouteParams(tiers_horse, 'horse.horse_id')
			let params = { tiershorse_id: tiershorse_id, horse_id: horse_id }
			EventBus.$emit('TableAction::goToDeleteTiersHorseHorse', params)
		},
		goToAddTiersHorseSecondaires(params) {
			EventBus.$emit('TableAction::goToAddTiersHorseSecondaires', params)
		},
		goToDeleteTiersHorseHistoriqueHorse(tiers_horse_part) {
			const tiershorsepart_id = this.constructRouteParams(tiers_horse_part, 'tiershorsepart_id')
			let params = { tiershorsepart_id: tiershorsepart_id }
			EventBus.$emit('TableAction::goToDeleteTiersHorseHistoriqueHorse', params)
		},
		goToPdfTiersHorseHorse(tiers_horse) {
			const horse_ids = this.constructRouteParams(tiers_horse, 'horse.horse_id')
			let params = { horse_ids: horse_ids }
			EventBus.$emit("TableAction::goToPdfTiersHorseHorse", params)
		},

		// INVOICES
		goToDuplicateInvoice(invoices) {
			const invoice_ids = this.constructRouteParams(invoices, 'invoice_id')
			let params = { invoice_ids: invoice_ids }
			EventBus.$emit("TableAction::goToDuplicateInvoice", params)
		},
		goToAddInvoice(invoice_details) {
			const invoicedetails_id = this.constructRouteParams(invoice_details, 'invoicedetails_id')
			let params = { invoicedetails_id: invoicedetails_id }

			EventBus.$emit("TableAction::goToAddInvoice", params)
		},
		goToPdfInvoiceWithAdvancedPayment(invoices) {
			const invoice_ids = this.constructRouteParams(invoices, 'invoice_id')
			const invoice_nums = this.constructRouteParams(invoices, 'invoice_num')
			let params = { invoice_ids: invoice_ids, invoice_nums: invoice_nums }

			EventBus.$emit("TableAction::goToPdfInvoiceWithAdvancedPayment", params)
		},
		goToPdfInvoice(invoices) {
			const invoice_ids = this.constructRouteParams(invoices, 'invoice_id')
			const invoice_nums = this.constructRouteParams(invoices, 'invoice_num')
			let params = { invoice_ids: invoice_ids, invoice_nums: invoice_nums }

			EventBus.$emit("TableAction::goToPdfInvoice", params)
		},
		goToSetRelanceManuelle(invoices) {
			const invoice_ids = this.constructRouteParams(invoices, 'invoice_id')
			let params = { invoice_ids: invoice_ids }

			EventBus.$emit("TableAction::goToSetRelanceManuelle", params)
		},
		goToSendInvoice(invoices) {
			const invoice_ids = this.constructRouteParams(invoices, 'invoice_id')
			const author_ids = invoices.map((invoice) => {
				if(invoice.invoice) {
					return invoice.invoice.invoice_author
				}
				else {
					return invoice.author.tiers_id
				}
			})
			let params = { 
				invoice_ids: invoice_ids,
				author_ids: author_ids
			}

			EventBus.$emit("TableAction::goToSendInvoice", params)
		},
		goToExportComptable(invoices) {
			const invoice_ids = this.constructRouteParams(invoices, 'invoice_id')
			let params = { invoice_ids: invoice_ids }

			EventBus.$emit("TableAction::goToExportComptable", params)
		},
		goToAddInvoiceDetail(invoice_details) {

			const invoicedetails_id = this.constructRouteParams(invoice_details, 'invoicedetails_id')

            // let params = { entity_id: entity_id, horse_id: horse_id, tiers_id: tiers_id }
            let params = { invoicedetails_id: invoicedetails_id }

			EventBus.$emit("TableAction::goToAddInvoiceDetail", params)
		},
		goToCancelInvoiceDetail(invoice_details) {
			const invoicedetails_id = this.constructRouteParams(invoice_details, 'invoicedetails_id')

            let params = { invoicedetails_id: invoicedetails_id }

			EventBus.$emit("TableAction::goToCancelInvoiceDetail", params)
		},
		goToDedoublonnerInvoiceDetail(invoice_details) {
			const invoicedetails_id = this.constructRouteParams(invoice_details, 'invoicedetails_id')

            let params = { invoicedetails_id: invoicedetails_id }

			EventBus.$emit("TableAction::goToDedoublonnerInvoiceDetail", params)
		},
		goToRecheckVat(invoice_details) {
			const invoicedetails_id = this.constructRouteParams(invoice_details, 'invoicedetails_id')

            let params = { invoicedetails_id: invoicedetails_id }

			EventBus.$emit("TableAction::goToRecheckVat", params)
		},
		goToPreviewInvoice(invoice_details) {
			const invoicedetails_id = this.constructRouteParams(invoice_details, 'invoicedetails_id')

            let params = { invoicedetails_id: invoicedetails_id }

			EventBus.$emit("TableAction::goToPreviewInvoice", params)
		},
		goToCopyInvoiceDetail(invoice_details) {
			EventBus.$emit("TableAction::goToCopyInvoiceDetail", invoice_details[0])
		},
		goToValideFacturationAValider(horse_actes) {
			EventBus.$emit("TableAction::goToValideFacturationAValider", horse_actes)
		},
		goToModalFactureAvoir(horse_actes) {
			EventBus.$emit("TableAction::goToModalFactureAvoir", horse_actes)
		},

		// INVOICES SUPPLIERS
		goToAddSupplierInvoice() {
			EventBus.$emit("TableAction::goToModalAddSupplierInvoice")
		},
		goToEditSupplierInvoice(invoice_suppliers) {
			const params = { 'invoicesuppliers_id': invoice_suppliers[0].invoice_id }
			EventBus.$emit("TableAction::goToEditSupplierInvoice", params)
		},
		goToSeeSupplierInvoiceDetails(invoicesuppliers) {
			EventBus.$emit("TableAction::goToSeeSupplierInvoiceDetails", invoicesuppliers[0])
		},
		goToEditSupplierInvoiceDetails(invoicesuppliers) {
			EventBus.$emit("TableAction::goToEditSupplierInvoiceDetails", invoicesuppliers[0])
		},
		goToAddSupplierInvoiceDetails(params) {
			EventBus.$emit("TableAction::goToAddSupplierInvoiceDetails")
		},

		// PAYMENT
		goToAddPayment(invoices) {
			const invoice_ids = this.constructRouteParams(invoices, 'invoice_id')
			const invoice_nums = this.constructRouteParams(invoices, 'invoice_num')
			const invoice_tiers = this.constructRouteParams(invoices, 'invoice_tiers')
			const invoice_authors = this.constructRouteParams(invoices, 'invoice_author')
			const invoice_accountingplans = this.constructRouteParams(invoices, 'invoice_accountingplan')
			const invoice_balances = this.sumConstructRouteParams(invoices, 'invoice_balance')
			const invoice_ttc = invoices.map(invoice => invoice.invoice_ttc)

			let params = { 
				invoice_ids: invoice_ids, 
				invoice_nums: invoice_nums, 
				invoice_tiers: invoice_tiers, 
				invoice_accountingplans: invoice_accountingplans, 
				invoice_authors: invoice_authors, 
				invoice_balances: invoice_balances,
				invoice_ttc: invoice_ttc
			}

			EventBus.$emit("TableAction::goToAddPayment", params)
		},

		goToUnpaidPayment(payment) {

			const payment_ids = this.constructRouteParams(payment, 'payment_id')

			let params = { payment_ids: payment_ids }

			EventBus.$emit("TableAction::goToUnpaidPayment", params)
		},

		goToCancelPayment(payment) {

			const payment_ids = this.constructRouteParams(payment, 'payment_id')

			let params = { payment_ids: payment_ids }

			EventBus.$emit("TableAction::goToCancelPayment", params)
		},

		goToExportPayment(payments) {
			const payment_ids = this.constructRouteParams(payments, 'payment_id')
			let params = { payment_ids: payment_ids }

			EventBus.$emit("TableAction::goToExportPayment", params)
		},

		goToEditPayment(payment) {
			EventBus.$emit("TableAction::goToEditPayment", payment[0])
		},

		// COMPTABILITE
		goToAddComptabilite() {
			EventBus.$emit("TableAction::goToAddComptabilite", {})
		},
		goToEditComptabilite(accounting) {
			const accountingplan_id = this.constructRouteParams(accounting, 'accountingplan_id')
			let params = { accountingplan_id: accountingplan_id }

			EventBus.$emit("TableAction::goToEditComptabilite", params)
		},
		goToRegenerateClientAccount(accounting) {
			const accountingplan_id = this.constructRouteParams(accounting, 'accountingplan_id')
			let params = { accountingplan_id: accountingplan_id }

			EventBus.$emit("TableAction::goToRegenerateClientAccount", params)
		},
		goToDuplicateAccountingPlan(accounting) {
			const accountingplan_id = this.constructRouteParams(accounting, 'accountingplan_id')
			let params = { accountingplan_id: accountingplan_id }

			EventBus.$emit("TableAction::goToDuplicateAccountingPlan", params)
		},
		goToAddAccountingAccount() {
			EventBus.$emit("TableAction::goToAddAccountingAccount", {})
		},
		goToImportAccountingAccount() {
			EventBus.$emit("TableAction::goToImportAccountingAccount", {})
		},
		goToEditAccountingAccount(accounting) {
			const accountingaccount_id = this.constructRouteParams(accounting, 'accountingaccount_id')
			let params = { accountingaccount_id: accountingaccount_id }

			EventBus.$emit("TableAction::goToEditAccountingAccount", params)
		},
		goToDeleteAccountingAccount(accounting) {
			const accountingaccount_id = this.constructRouteParams(accounting, 'accountingaccount_id')
			let params = { accountingaccount_id: accountingaccount_id }

			EventBus.$emit("TableAction::goToDeleteAccountingAccount", params)
		},
		goToArchiveAccounting(accountings) {
			const accounting_plan_ids = accountings.map((accounting) => {
				return accounting.accountingplan_id
			})

			EventBus.$emit("TableAction::goToArchiveAccounting", accounting_plan_ids)
		},
		goToUnarchiveAccounting(accountings) {
			const accounting_plan_ids = accountings.map((accounting) => {
				return accounting.accountingplan_id
			})

			EventBus.$emit("TableAction::goToUnarchiveAccounting", accounting_plan_ids)
		},
		goToAddActeTypeArticle() {
			EventBus.$emit("TableAction::goToAddActeTypeArticle", {})
		},
		goToEditActeTypeArticle(actes_type) {
			const actestype_id = this.constructRouteParams(actes_type, 'actestype_id')
			let params = { actestype_id: actestype_id }

			EventBus.$emit("TableAction::goToEditActeTypeArticle", params)
		},
		goToAddCheckDepositConfiguration() {
			EventBus.$emit("TableAction::goToAddCheckDepositConfiguration", {})
		},
		goToEditCheckDepositConfiguration(check_deposit) {
			const checkdepositconfiguration_id = this.constructRouteParams(check_deposit, 'checkdepositconfiguration_id')
			let params = { checkdepositconfiguration_id: checkdepositconfiguration_id }

			EventBus.$emit("TableAction::goToEditCheckDepositConfiguration", params)
		},
		goToDeleteCheckDepositConfiguration(check_deposit_config) {
			const checkdepositconfiguration_id = this.constructRouteParams(check_deposit_config, 'checkdepositconfiguration_id')
			let params = { checkdepositconfiguration_id: checkdepositconfiguration_id }

			EventBus.$emit("TableAction::goToDeleteCheckDepositConfiguration", params)
		},
		goToDeleteCheckDeposit(check_deposit) {
			const checkdeposit_id = this.constructRouteParams(check_deposit, 'checkdeposit_id')
			let params = { checkdeposit_ids: checkdeposit_id }

			EventBus.$emit("TableAction::goToDeleteCheckDeposit", params)
		},

		goToCheckDepositConfig(checks) {
			const payment_id = this.constructRouteParams(checks, 'payment_id')
			let params = { payment_id: payment_id }

			EventBus.$emit("TableAction::goToCheckDepositConfig", params)
		},

		goToAddCheckDeposit() {
			EventBus.$emit("TableAction::goToAddCheckDeposit", {})
		},

		goToCashCheckDeposit(check_deposit) {
			const checkdeposit_id = this.constructRouteParams(check_deposit, 'checkdeposit_id')
			let params = { checkdeposit_ids: checkdeposit_id }

			EventBus.$emit("TableAction::goToCashCheckDeposit", params)
		},

		goToPrintCheckDeposit(check_deposit) {
			const checkdeposit_id = this.constructRouteParams(check_deposit, 'checkdeposit_id')
			let params = { checkdeposit_ids: checkdeposit_id }

			EventBus.$emit("TableAction::goToPrintCheckDeposit", params)
		},
		// MODEL
		goToEditModel(model) {
			const model_id = this.constructRouteParams(model, 'model_id')
			let params = {
				model_id: model_id,
				modelable_type: model[0].type.modeltype_relation
			}
			EventBus.$emit("TableAction::goToEditModel", params)
		},

		goToAddModel() {
			EventBus.$emit("TableAction::goToAddModel", {})
		},

		// LIEUX
		goToSelectLieu(lieux) {
			EventBus.$emit('TableAction::SelectLieu', lieux[0])
		},
		goToAddLieu() {
			EventBus.$emit('TableAction::AddLieu')
		},
		goToEditLieu(lieux) {
			EventBus.$emit('TableAction::EditLieu', lieux[0])
		},
		goToDeleteLieu(lieux) {
			EventBus.$emit('TableAction::DeleteLieux', lieux)
		},

		// HORSE
		goToAddTiersHorseTiers() {
			EventBus.$emit("TableAction::goToAddTiersHorseTiers", {})
		},
		goToEditTiersHorseTiers(tiers_horse) {
			const tiershorse_id = this.constructRouteParams(tiers_horse, 'tiershorse_id')
			let params = { tiershorse_id: tiershorse_id }
			EventBus.$emit("TableAction::goToEditTiersHorseTiers", params)
		},
		goToDeleteTiersHorseTiers(tiers_horse) {
			const tiershorse_id = this.constructRouteParams(tiers_horse, 'tiershorse_id')
			const tiers_id = this.constructRouteParams(tiers_horse, 'tiers.tiers_id')
			let params = { tiershorse_id: tiershorse_id, tiers_id: tiers_id }
			EventBus.$emit('TableAction::goToDeleteTiersHorseTiers', params)
		},
		goToDeleteTiersHorseHistoriqueTiers(tiers_horse_part) {
			const tiershorsepart_id = this.constructRouteParams(tiers_horse_part, 'tiershorsepart_id')
			let params = { tiershorsepart_id: tiershorsepart_id }
			EventBus.$emit('TableAction::goToDeleteTiersHorseHistoriqueTiers', params)
		},
		goToDeleteMultipleTiersHorseHistoriqueTiers(tiers_horse_part) {
			const tiershorsepart_id = this.constructRouteParams(tiers_horse_part, 'tiershorsepart_id')
			let params = { tiershorsepart_id: tiershorsepart_id }
			EventBus.$emit('TableAction::goToDeleteMultipleTiersHorseHistoriqueTiers', params)
		},
		goToPdfTiersHorseTiers() {
			EventBus.$emit("TableAction::goToPdfTiersHorseTiers", {})
		},
		goToAddTiersSecondaires() {
			EventBus.$emit('TableAction::goToAddTiersSecondaires')
		},
		goToEditTiersSecondaires(tiers_horse) {
			EventBus.$emit('TableAction::goToEditTiersSecondaires', tiers_horse)
		},
		goToDeleteTiersSecondaires(tiers_horse) {
			const tiershorse_ids = this.constructRouteParams(tiers_horse, 'tiershorse_id')
			EventBus.$emit('TableAction::goToDeleteTiersSecondaires', tiershorse_ids)
		},

		// RELATIONS TIERS
		goToAddRelations() {
			EventBus.$emit('TableAction::goToAddRelations')
		},
		goToEditRelations(tiers_relations) {
			EventBus.$emit('TableAction::goToEditRelations', tiers_relations)
		},
		goToDeleteRelations(tiers_relations) {
			const tiersrelations_ids = this.constructRouteParams(tiers_relations, 'tiersrelations_id')
			EventBus.$emit('TableAction::goToDeleteRelations', tiersrelations_ids)
		},

		// PENSION
		goToAddPensionType() {
			EventBus.$emit("TableAction::goToAddPensionType", {})
		},
		goToEditPensionType(pension) {
			const pension_id = this.constructRouteParams(pension, 'pension_id')
			let params = { pension_id: pension_id }

			EventBus.$emit("TableAction::goToEditPensionType", params)
		},
		goToAddHorsePension() {
			EventBus.$emit("TableAction::goToAddHorsePension", {})
		},
		goToEditHorsePension(pension) {
			const horsepension_id = this.constructRouteParams(pension, 'horsepension_id')
			let params = { horsepension_id: horsepension_id }

			EventBus.$emit("TableAction::goToEditHorsePension", params)
		},
		goToEditEndHorsePension(pension) {
			EventBus.$emit("TableAction::goToEditEndHorsePension", pension[0])
		},
		goToDeleteHorsePension(pension) {
			const horsepension_id = this.constructRouteParams(pension, 'horsepension_id')
			let params = { horsepension_id: horsepension_id }
			EventBus.$emit('TableAction::goToDeleteHorsePension', params)
		},
		goToDeleteHorsePensionHistorique(pension) {
			const horsepension_id = this.constructRouteParams(pension, 'horsepension_id')
			let params = { horsepension_id: horsepension_id }
			EventBus.$emit('TableAction::goToDeleteHorsePensionHistorique', params)
		},
		goToEditTrigger(trigger_entity) {
			const triggerentity_id = this.constructRouteParams(trigger_entity, 'triggerentity_id')
			let params = { triggerentity_id: triggerentity_id }
			EventBus.$emit('TableAction::goToEditTrigger', params)
		},
		goToAddTrigger(trigger_entity) {
			EventBus.$emit('TableAction::goToAddTrigger', { })
		},
		async goToDeleteTrigger(trigger_entity) {
			EventBus.$emit('TableAction::goToDeleteTrigger', trigger_entity)
		},
		goToSaveExport(exportperso) {
			EventBus.$emit('TableAction::goToSaveExport', exportperso[0])
		},

		// GYNECOLOGIE
		goToAddSeason() {
			EventBus.$emit("TableAction::goToAddSeason", {})
		},
		goToEditSeason(season) {
			const season_id = this.constructRouteParams(season, 'season_id')
			let params = { season_id: season_id }

			EventBus.$emit("TableAction::goToEditSeason", params)
		},
		goToDeleteSeason(season) {
			const season_id = this.constructRouteParams(season, 'season_id')
			let params = { season_id: season_id }

			EventBus.$emit("TableAction::goToDeleteSeason", params)
		},
		goToAddSeasonMareStallion() {
			EventBus.$emit("TableAction::goToAddSeasonMareStallion", {})
		},
		// goToEditSeasonMareStallion(seasonMareStallion) {
		// 	const seasonmarestallion_id = this.constructRouteParams(seasonMareStallion, 'seasonmarestallion_id')
		// 	let params = { seasonmarestallion_id: seasonmarestallion_id }

		// 	EventBus.$emit("TableAction::goToEditSeasonMareStallion", params)
		// },
		goToDeleteSeasonMareStallion(seasonMareStallion) {
			EventBus.$emit('TableAction::goToDeleteSeasonMareStallion', seasonMareStallion)
		},
		
		// Localisation
		goToAddLocalisation() {
			EventBus.$emit('TableAction::goToAddLocalisation')
		},
		goToEditLocalisation(loc) {
			EventBus.$emit('TableAction::goToEditLocalisation', loc)
		},
		goToDeleteLocalisation(loc) {
			EventBus.$emit('TableAction::goToDeleteLocalisation', loc)
		},
		// Qualification
		goToAddQualification(){
			EventBus.$emit("TableAction::goToAddQualification", {})
		},
		goToEditQualification(qualif){
			EventBus.$emit("TableAction::goToEditQualification", qualif)
		},
		goToDeleteQualification(qualifs){
			EventBus.$emit("TableAction::goToDeleteQualification", qualifs)
		},
		goToAddQualificationToFiche(){
			EventBus.$emit("TableAction::goToAddQualificationToFiche")
		},
		goToEditQualificationToFiche(qualif){
			EventBus.$emit("TableAction::goToEditQualificationToFiche", qualif[0])
		},
		goToDeleteQualificationToFiche(qualifs){
			EventBus.$emit("TableAction::goToDeleteQualificationToFiche", qualifs)
		},
		// Media
		goToAddMedia() {
			EventBus.$emit("TableAction::goToAddMedia")
		},
		goToEditMedia(media) {
			EventBus.$emit("TableAction::goToEditMedia", media[0])
		},
		goToViewMedia(media) {
			EventBus.$emit("TableAction::goToViewMedia", media[0])
		},
		goToDeleteMedia(medias) {
			EventBus.$emit("TableAction::goToDeleteMedia", medias)
		},
		// Contracts
		goToAddContract(params = null) {
			EventBus.$emit("TableAction::goToAddContract", params)
		},
		goToEditContract(contract) {
			const contract_id = this.constructRouteParams(contract, 'contract_id')
			let params = { contract_id: contract_id }
			EventBus.$emit("TableAction::goToEditContract", params)
		},
		goToDeleteContract(contracts) {
			const contract_ids = contracts.map((contract) => {
				return contract.contract_id
			})
			EventBus.$emit("TableAction::goToDeleteContract", contract_ids)
		},
		goToPrintContract(contracts) {
			const contract_ids = contracts.map((contract) => {
				return contract.contract_id
			})
			EventBus.$emit("TableAction::goToPrintContract", contract_ids)
		},
		goToPrintContractLot(contracts) {
			const contract_ids = contracts.map((contract) => {
				return contract.contract_id
			})
			EventBus.$emit("TableAction::goToPrintContractLot", contract_ids)
		},
		goToAddAccountingPaymentAccount() {
			EventBus.$emit("TableAction::goToAddAccountingPaymentAccount", {})
		},
		goToEditAccountingPaymentAccount(accounting) {
			const accountingpaymentaccount_id = this.constructRouteParams(accounting, 'accountingpaymentaccount_id')
			let params = { accountingpaymentaccount_id: accountingpaymentaccount_id }

			EventBus.$emit("TableAction::goToEditAccountingPaymentAccount", params)
		},

		// JUMENTERIE
		goToPdfSuivi(lines) {
			EventBus.$emit("TableAction::goToPdfSuivi", lines)
		},

		//Client Area
        goToNotifyAct(lines) {
            this.sendEvent('TableAction::notifyActs', lines.map(act => act.actes_id))
        },

        // SANITAIRE
        goToSendSanitaire(couples) {
            this.sendEvent('TableAction::goToSendSanitaire', couples)
        },

		// TOOLS
		constructHorseRouteParams(horses, params={}) {
			if(horses.length > 0) {
				const horse_ids = this.constructRouteParams(horses, 'horse_id')
				params.horse_id = horse_ids
			}
			else {
				const horse_id = this.$route.params.horse_id
				if(horse_id) {
					params.horse_id = horse_id
				}
			}

			return params
		},

		/* tab => tableau de lignes séléctionnées */
		/* param_name => donnée à en extraire pour construire la liste de params */
		constructRouteParams(tab, param_name) {
			const params = this.getArrayObjProperty(tab, param_name)
			return params.join(',')
		},
		sumConstructRouteParams(tab, param_name) {
			let params = this.getArrayObjProperty(tab, param_name)
			params = params.map(param => {
				return String(param).toFloat()
			})
			return params.reduce((pv, cv) => pv + cv, 0)
        },
        sendEvent(name, params) {
            if(this.uniqueEventId) {
                name += '::' + this.uniqueEventId
            }

            EventBus.$emit(name, params)
        }
	},
	computed: {
		fromComponent() {
			return this.$route.name
		}
	}
}

export default TableActionMixin
