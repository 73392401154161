import Transformer from './Transformer'
import AccountingPlanTransformer from './AccountingPlanTransformer'

export default class CurrentAccountTransformer extends Transformer {

	table = 'current_account'

	async fetch (col) {
	    return col.with({
	        'accounting_plan'	: 'currentaccount_accountingplan'
	    })
	}

    async transform(current_account) {
        return {
			'currentaccount_id': current_account.currentaccount_id,
			'currentaccount_tiers': current_account.currentaccount_tiers,
			'currentaccount_payment': current_account.currentaccount_payment,
			'currentaccount_amount': current_account.currentaccount_amount,
			'currentaccount_balance': current_account.currentaccount_balance,
			'currentaccount_accountingplan': current_account.currentaccount_accountingplan,
			'currentaccount_licencekey': current_account.currentaccount_licencekey,
			'currentaccount_valide': current_account.currentaccount_valide,
			'accounting_plan': await AccountingPlanTransformer.process(current_account.accounting_plan, 'light'),
        }
	}
}